.client__search {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    max-width: 820px;
    margin: 0 auto;
}

.client__input-text {
    width: 40px;
}

.client__button__get {
    width: 300px;
}

.client__button__clear {
    width: 205px;
}