.auth__form {
    width: 500px;
    background-color: #b1b1b1;
    padding: 15px;
    margin: 150px auto 0 auto;
}

.auth__title {
    font-size: 26px;
    text-align: center;
}

.auth__button {
    width: 100%;
}