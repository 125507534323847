.get-file__text {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.get-file__process {
    display: flex;
    justify-content: center;
    align-items: center;
}

.get-file__loader {
    width: 36px;
    height: 36px;
}