.input__hint {
    width: 85px;
}

.input__date {
    width: 100%;
    max-width: 300px;
    padding: 0 13px;
}

.input__icon {
    cursor: pointer;
}


textarea::placeholder {
    color: var(--placeholder-color) !important;
}
input::placeholder {
    color: var(--placeholder-color) !important;
}
