.client__title {
    text-align: center;
    font-size: 28px;
}

.client__container {
    padding: 30px;
}

.client__subtitle {
    font-size: 22px;
    font-weight: 500;
}
