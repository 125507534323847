.attach-file {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.attach-file__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.attach-file__text {

}

.attach-file__input {
    label {
        padding: 0.375rem 0.75rem;
        background-color: #0d6efd;
        color: var(--white);
        cursor: pointer;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
    }
    label:hover{
        background-color: #0b5ed7;
    }
    input {
        display: none;
    }
}