* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --placeholder-color: #c6c6c6;
  --background-color: #e8e7e7;
  --bs-tertiary-bg: #d6d6d6;
  --white: #fff;
  --bs-secondary-bg: #bfc4ca;
}

html {
  height: 100%;
  width: 100%;
  background-color: var(--background-color);
}

#root {
  background-color: var(--background-color);
}

.container {
  min-width: 100%;
  min-height: 100%;
  background-color: var(--background-color);
  padding: 30px;
}

.text__error {
  text-align: center;
  color: red;
  font-size: 18px;
}