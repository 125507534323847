.files__list-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid black;
}

.files__list-row__date {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    font-weight: 600;
    padding: 10px;
}

.files__list-row__files {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 85%;
    gap: 15px;
    border-left: 3px solid black;
    padding: 10px;
}

.files__list-row:last-child {
    border-bottom: none;
}
.files__list-row:last-child .files__list-row__files {
    border-bottom: none;
}