.file-item {
    padding: 7px;
    border: 1px solid black;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.file-item__icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-item__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.file-item__name {
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
}

.file-item__sender {
    font-size: 13px;
    font-weight: 500;
}