.files {

}

.files__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.files__filter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.files__container {
    width: 100%;
    border: 3px solid black;
    border-radius: 8px;
}