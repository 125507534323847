.navigation {
    width: 100%;
    height: 50px;
    background-color: #3a3939;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
}

.nav__container {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
}

.nav__item {
    color: white;
    font-size: 19px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
}

.nav__item__active:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--white);
}

.nav__item:hover:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--white);
}