.user-info__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
}

.user-info__hint {
    width: 250px;
}

.user-info__textarea {
    textarea {
        height: 15px;
    }
}